import React, { useEffect, useState } from "react";
import apiHandler from "api";
import endpoint from "api/endpoint";
import { Form, FormGroup, Alert, Spinner } from "reactstrap";
import {
  StyledPillButton,
  FormErrorText,
  AuthenticationContainer,
  AuthenticationCard,
  AuthFlowLink
} from "theme/StyledComponents";
import Logo from "assets/header-logo.png";
import { UncontrolledInput } from "components/CustomInput/CustomInput";
import { useForm } from "react-hook-form";
import { useLocation } from "react-router-dom";
import Joi from "joi";

/*
 * Register component validates email input first
 * valid email is "locked" before allow user to send registration email
 * This is to enable SSO check to properly redirect user to SSO login/registration
 * Note: email is validated and warning is shown upon loss of focus to FormGroup
 */
const Register = () => {
  const location = useLocation();
  const urlParams = new URLSearchParams(location.search);
  const emailAddress = urlParams.get("email")?.replace(/\s/g, "+"); // TODO(MB) this is to handle not encoding/decoding url params correctly yet

  const {
    register,
    getValues,
    watch,
    formState: { dirtyFields },
    handleSubmit
  } = useForm({
    defaultValues: { email: emailAddress || "" }
  });
  const emailInput = watch("email");
  const [showError, setShowError] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [showForm, setShowForm] = useState(true);
  const [isLoading, setIsLoading] = useState();
  const [emailValid, setEmailValid] = useState(false);
  const [submitStatus, setSubmitStatus] = useState(false);
  const [focus, setFocus] = useState(true);

  const checkSSOStatus = async () => {
    const result = await apiHandler({
      url: endpoint.LOGIN_SSO,
      method: "POST",
      data: getValues()
    });

    const { data } = result;

    // * check if sso and if so -> redirect to sso login -> else it's a valid email
    if (data.success) {
      window.location.replace(data.redirect);
    } else {
      // * enable (change email) button and lock input down
      setSubmitStatus(true);
    }
  };

  // * check email for validity as soon as input isDirty
  useEffect(() => {
    if (dirtyFields["email"]) {
      const schema = Joi.string().email({ tlds: { allow: false } });
      const test = !schema.validate(emailInput).error;
      setEmailValid(test);
    }
  }, [setEmailValid, dirtyFields, emailInput]);

  // * only allow submit action to run if email is valid
  const handleSetSubmitStatus = () => {
    if (emailValid) {
      checkSSOStatus().catch((e) => console.log("CHECK SSO ERROR:", e.message));
    }
  };

  const handleChangeEmail = () => {
    setSubmitStatus(false);
  };

  const registerUser = React.useCallback(async (formValues) => {
    const { email } = formValues;
    setIsLoading(true);
    await apiHandler({
      url: endpoint.SIGNUP,
      method: "POST",
      data: { email: email.trim().toLowerCase() }
    })
      .then(({ data }) => {
        if (!!data && data.success) {
          if (data.redirect) {
            window.location.replace(data.redirect);
          }
          setShowError(false);
          setShowForm(false);
          setShowSuccess(true);
        } else if (!!data && data.success === false) {
          setErrorMessage(data.message || "Something went wrong. Please try again");
          setShowError(true);
        }
      })
      .catch((err) => {
        setShowError(true);
        console.log("registration error:", err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  if (isLoading)
    return (
      <div
        style={{
          background: "white",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh"
        }}
      >
        <Spinner size="lg" color="dark" />
      </div>
    );

  const successMessage = "Registration Link Sent! Please check your email.";

  const handleFormSubmit = (e) => {
    // * prevent enter key from refreshing page and enforce use of submit button
    e.preventDefault();
  };

  return (
    <AuthenticationContainer>
      <AuthenticationCard>
        <img alt="Katilyst logo" src={Logo} width="100%" />
        <Form onSubmit={handleFormSubmit}>
          <Alert color="danger" isOpen={showError} className="my-5">
            {errorMessage}
            &nbsp;
          </Alert>
          <Alert color="success" isOpen={showSuccess} className="my-5">
            {successMessage}
          </Alert>
          {showForm && (
            <>
              <FormGroup className="mt-5 mb-4" onFocus={() => setFocus(true)} onBlur={() => setFocus(false)}>
                <span>
                  <UncontrolledInput
                    style={{ width: "100%" }}
                    formRegister={register}
                    validation={{
                      required: "Required"
                    }}
                    name="email"
                    labelText="EMAIL"
                    height="3.5rem"
                    className={!emailValid && !!emailInput && !focus && "error"}
                    disabled={submitStatus}
                  />
                  <FormErrorText className="m-0" data-testid="EMAIL_VALIDATION_WARNING">
                    {!emailValid && !!emailInput && !focus && "Please enter a valid email"}
                  </FormErrorText>
                </span>
              </FormGroup>
              {!submitStatus ? (
                <StyledPillButton
                  data-testid="REGISTER_SUBMIT_BUTTON"
                  style={{ width: "14rem" }}
                  type="button"
                  className="mx-auto"
                  onClick={() => handleSetSubmitStatus()}
                >
                  Submit
                </StyledPillButton>
              ) : (
                <div className="d-flex justify-content-around">
                  <StyledPillButton
                    className="mx-auto"
                    type="button"
                    data-testid="CHANGE_EMAIL"
                    onClick={handleChangeEmail}
                    style={{ width: "12rem" }}
                  >
                    Change Email
                  </StyledPillButton>
                  <StyledPillButton
                    style={{ width: "14rem" }}
                    type="submit"
                    className="mx-auto"
                    data-testid="REGISTER_SEND_BUTTON"
                    onClick={handleSubmit(registerUser)}
                    disabled={!emailValid}
                  >
                    Send Registration Email
                  </StyledPillButton>
                </div>
              )}
            </>
          )}
        </Form>
        <AuthFlowLink to="/login" className="text-center my-3">
          Already have an account? <span>Log in</span>
        </AuthFlowLink>
      </AuthenticationCard>
    </AuthenticationContainer>
  );
};

export default React.memo(Register);
